import { render, staticRenderFns } from "./AuthParent.vue?vue&type=template&id=84fe4754&scoped=true&"
import script from "./AuthParent.vue?vue&type=script&lang=js&"
export * from "./AuthParent.vue?vue&type=script&lang=js&"
import style0 from "./AuthParent.vue?vue&type=style&index=0&id=84fe4754&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "84fe4754",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/usr/share/nginx/testhtml/front/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('84fe4754')) {
      api.createRecord('84fe4754', component.options)
    } else {
      api.reload('84fe4754', component.options)
    }
    module.hot.accept("./AuthParent.vue?vue&type=template&id=84fe4754&scoped=true&", function () {
      api.rerender('84fe4754', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/common/AuthParent.vue"
export default component.exports