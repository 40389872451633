<template>
    <a-layout-footer  class="c-footer"
    :style="{
      position: 'fixed !important',
      bottom: '0 !important',
      'text-align' : 'center',
      height: '3rem',
      padding : '0.7% 0% 1% 0%',
      width: '100%'
    }">
      ©{{now('YYYY')}} 
      <img :src="require('@/assets/naltis_logo.png')">
    </a-layout-footer>
</template>
<script>
export default {
  name: "Footer"
  };
</script>
<style scoped>
.ant-layout-footer{
  color: rgb(243, 213, 213);
  text-align: center;
}
.c-footer{
   background:#d1d4d8;
}
img{
    width: auto;
    height: auto;
}
</style>
