var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-layout-footer",
    {
      staticClass: "c-footer",
      style: {
        position: "fixed !important",
        bottom: "0 !important",
        "text-align": "center",
        height: "3rem",
        padding: "0.7% 0% 1% 0%",
        width: "100%"
      }
    },
    [
      _vm._v("\n  ©" + _vm._s(_vm.now("YYYY")) + " \n  "),
      _c("img", { attrs: { src: require("@/assets/naltis_logo.png") } })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }